<template>
    <BaseHero
        :image="hero.image"
        :info-list="hero.infoList"
        :description="hero.description"
        class="content-page-hero"
    >
        <template #title>{{ hero.title }}</template>

        <template #meta>
            <MetaLine>
                <template #default>
                    {{ heroTypeLabel }}
                </template>
                <template v-if="hero.date || hero.dateRange" #after>
                    <DateOrDateRange :entry="hero" />
                </template>
            </MetaLine>
        </template>
    </BaseHero>
</template>

<script setup>
const { t } = useI18n();

const props = defineProps({
    hero: {
        type: Object,
        required: true,
        default: () => {}
    }
});

const heroTypeLabel = computed(() => {
    if (props.hero.eventType) {
        return props.hero.eventType;
    }

    if (props.hero.educationContentType) {
        return props.hero.educationContentType;
    }

    if (props.hero.pressType) {
        return props.hero.pressType;
    }

    if (props.hero.collectionType) {
        return props.hero.collectionType;
    }

    return t(`sections.${props.hero.section}`);
});
</script>

<style lang="less" src="./ContentPageHero.less" />
