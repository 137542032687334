<template>
    <div
        v-if="entry"
        class="template template--content-page"
    >
        <ContentPageHero :hero="hero" />
        <slot name="default">
            <FlexibleSections :sections="entry.flexibleSections" />
        </slot>

        <BaseSection v-if="$slots.cta" class="template--content-page__cta">
            <slot name="cta" />
        </BaseSection>
    </div>
</template>

<script setup>
const props = defineProps({
    entry: {
        type: Object,
        default: () => {},
        required: true
    }
});

const hero = useHeroData(props.entry);
</script>

<style lang="less">
.template--content-page__cta {
    display: flex;
    justify-content: center;
}
</style>
